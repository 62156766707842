.logoFillPrimary {
    fill: rgb(0, 64, 106);
}

.logoFillSecondary {
    fill: rgb(119, 154, 186);
}

.logoFillAccent {
    fill: rgb(91, 186, 74);
}

.logoFillLight {
    fill: rgb(190, 223, 176);
}

/* Animate the mask rectangle */
#mask-rect {
    animation: revealLogo 2s forwards;
}

@keyframes revealLogo {
    to {
        width: 100%;
    }
}