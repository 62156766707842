@import-normalize;

@import './fonts/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');


/* Colors */
/* Light mode */
:root {
  --border-pulsating: #F4F4F5;
  --hover-dk: #3F3F46;
  --txt-dk-placeholder: #A1A1AA;
  --logo-primary-color: rgb(0, 64, 106);
  --logo-secondary-color: rgb(119, 154, 186);
}

/* Dark mode */
  :root.dark {
    --border-pulsating: #27272A;
    --logo-primary-color: white;
    --logo-secondary-color: rgb(119, 154, 186);
  }

@layer utilities {
  .text-header {}
}


/* CardGrid styles */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  width: 100%;
  /* padding: 16px; */
}

.main-content {
  overflow: hidden;
  /* Prevent scrolling of the entire content */
}

.table-container {
  width: 100%;
  height: 100%;
  /* Ensure full height for vertical scroll */
  overflow-x: auto;
  /* Enable horizontal scrolling for table only */
  overflow-y: auto;
  /* Enable vertical scrolling for table only */
}

/* Charging Animation */
@keyframes variable-spin {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
  }

  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }

  100% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
  }
}

.animate-variable-spin {
  animation: variable-spin 1s infinite, move-gradient 4s infinite;
}


/* Map */
.map-container {
  height: 400px;
  width: 100%;
  background-color: var(--bg-lg-content);
}

@media (max-width: 768px) {
  .map-container {
    height: 300px;
  }
}


/* Card styles */
.hover-effect-card:hover {
  opacity: 0.4;
}

.hover-effect-card .card-background {
  transition: background-color 0.15s, opacity 0.15s;
}

.hover-effect-card:hover .card-background {
  opacity: 0.8;
}

.status-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px 0;
  text-align: center;
  font-weight: bold;
  color: white;
  border-radius: inherit;
  font-size: 0.875rem;
  margin-bottom: 4px;
}

/* skeleton.css */
.skeleton-dark-mode {
  background-color: var(--hover-dk) !important;
  color: var(--txt-dk-placeholder) !important;
}

.blur {
  filter: blur(4px);
  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.whitespace-pre {
  white-space: pre;
}