
/* src/fonts.css */

@font-face {
    font-family: 'TOROKA';
    src: url('./Toroka-WideMedium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  